const scores = [
  {
    path: '/score',
    name: 'score-list',
    component: () => import('@/views/scores/Score.vue'),
    meta: {
      layout: 'content',
    },
  },
]
export default scores
