const promotion = [
  {
    path: '/promotion-admin',
    name: 'promotion-admin',
    component: () => import('@/views/promotions/superadmin/Promotion.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'PromotionAdmin',
    },
  },
]
export default promotion
