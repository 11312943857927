import { mdiCardAccountDetailsOutline } from '@mdi/js'

export default {
  subjectschool: {
    title: 'Subject',
    to: 'lesson-list',
    icon: mdiCardAccountDetailsOutline,
    resource: 'Subject',
    action: 'read',
  },
}
