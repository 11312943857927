import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = '/api/v1/violation'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`)
  },
  get(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  update(violation, uuid) {
    return http.post(`${resource}/${uuid}?_method=PUT`, violation)
  },
  add(violation) {
    return http.post(`${resource}`, violation)
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
}
