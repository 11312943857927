import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resourceList = '/api/v1/calendar-school'
const resource = '/api/v1/calendar'

export default {
  list(params = {}) {
    const schoolUuid = JSON.parse(localStorage.getItem('user')).user.details[0].uuid
    const paramsString = paramsConverter(params)

    return http.get(`${resourceList}/${schoolUuid}${paramsString}`)
  },
  get(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  update(event, uuid) {
    return http.put(`${resource}/${uuid}`, event)
  },
  add(event) {
    return http.post(`${resource}`, event)
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
}
