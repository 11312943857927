const schoolFund = [
  {
    path: '/school-fund',
    name: 'school-fund-list',
    component: () => import('@/views/school-fund/SchoolFund.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'FundHeadMaster',
    },
  },
]
export default schoolFund
