const evoting = [
  {
    path: '/evoting-school',
    name: 'evoting-school-list',
    component: () => import('@/views/evoting/school/Evoting.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'EvotingSchool',
    },
  },
  {
    path: '/evoting-teacher',
    name: 'evoting-teacher-list',
    component: () => import('@/views/evoting/teacher/Evoting.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'EvotingTeacher',
    },
  },
]
export default evoting
