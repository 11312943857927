const functionaries = [
  {
    path: '/functionary-school',
    name: 'functionary-school-list',
    component: () => import('@/views/functionaries/school/Functionary.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'FunctionarySchool',
    },
  },
  {
    path: '/functionary-headmaster',
    name: 'functionary-headmaster-list',
    component: () => import('@/views/functionaries/headmaster/Functionary.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'FunctionaryHeadMaster',
    },
  },
]
export default functionaries
