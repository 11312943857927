const announcements = [
  {
    path: '/announcement-school',
    name: 'announcement-school-list',
    component: () => import('@/views/announcements/school/Announcement.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'AnnouncementSchool',
    },
  },
  {
    path: '/announcement-hod',
    name: 'announcement-hod-list',
    component: () => import('@/views/announcements/hod/Announcement.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'AnnouncementHeadDepartment',
    },
  },
]

export default announcements
