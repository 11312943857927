const forum = [
  {
    path: '/forum',
    name: 'forum-list',
    component: () => import('@/views/forum-bookmark/ForumBookmark.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'Forum',
    },
  },
  {
    path: '/forum/add',
    name: 'forum-add',
    component: () => import('@/views/forum-bookmark/forum/ForumAdd.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'Forum',
    },
  },
  {
    path: '/forum/detail/:uuid',
    name: 'forum-detail',
    component: () => import('@/views/forum-bookmark/forum/ForumDetail.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'Forum',
    },
  },
]
export default forum
