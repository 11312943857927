const schoolData = [
  {
    path: '/school-data-hod',
    name: 'school-data-hod-list',
    component: () => import('@/views/school-data/hod/SchoolData.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolDataHeadDepartment',
    },
  },
  {
    path: '/school-data-hod/detail/:uuid',
    name: 'school-data-hod-detail',
    component: () => import('@/views/school-data/hod/SchoolDataDetail.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolDataHeadDepartment',
    },
  },
  {
    path: '/school-data-hod/school/student/:uuid',
    name: 'school-data-hod-student',
    component: () => import('@/views/school-data/hod/SchoolDataStudent.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolDataHeadDepartment',
    },
  },
  {
    path: '/school-data-hod/school/teacher/:uuid',
    name: 'school-data-hod-teacher',
    component: () => import('@/views/school-data/hod/SchoolDataTeacher.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolDataHeadDepartment',
    },
  },
  {
    path: '/school-data-hod/school/bankquestion/:uuid',
    name: 'school-data-hod-bankquestion',
    component: () => import('@/views/school-data/hod/SchoolDataBankQuestion.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolDataHeadDepartment',
    },
  },
  {
    path: '/school-data-hod/school/cbt/:uuid',
    name: 'school-data-hod-cbt',
    component: () => import('@/views/school-data/hod/SchoolDataCbt.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolDataHeadDepartment',
    },
  },
  {
    path: '/school-data-hod/school/student-attendance/:uuid',
    name: 'school-data-hod-student-attendance',
    component: () => import('@/views/school-data/hod/SchoolDataStudentAttendance.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolDataHeadDepartment',
    },
  },
  {
    path: '/school-data-hod/school/fund/:uuid',
    name: 'school-data-hod-fund',
    component: () => import('@/views/school-data/hod/SchoolDataFund.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolDataHeadDepartment',
    },
  },
  {
    path: '/school-data-hob',
    name: 'school-data-hob-list',
    component: () => import('@/views/school-data/hob/SchoolData.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolDataHeadBranch',
    },
  },
  {
    path: '/school-data-hob/detail/:uuid',
    name: 'school-data-hob-detail',
    component: () => import('@/views/school-data/hob/SchoolDataDetail.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolDataHeadBranch',
    },
  },
  {
    path: '/school-data-hob/school/student/:uuid',
    name: 'school-data-hob-student',
    component: () => import('@/views/school-data/hob/SchoolDataStudent.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolDataHeadBranch',
    },
  },
  {
    path: '/school-data-hob/school/teacher/:uuid',
    name: 'school-data-hob-teacher',
    component: () => import('@/views/school-data/hob/SchoolDataTeacher.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolDataHeadBranch',
    },
  },
  {
    path: '/school-data-hob/school/bankquestion/:uuid',
    name: 'school-data-hob-bankquestion',
    component: () => import('@/views/school-data/hob/SchoolDataBankQuestion.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolDataHeadBranch',
    },
  },
  {
    path: '/school-data-hob/school/cbt/:uuid',
    name: 'school-data-hob-cbt',
    component: () => import('@/views/school-data/hob/SchoolDataCbt.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolDataHeadBranch',
    },
  },
  {
    path: '/school-data-hob/school/student-attendance/:uuid',
    name: 'school-data-hob-student-attendance',
    component: () => import('@/views/school-data/hob/SchoolDataStudentAttendance.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolDataHeadBranch',
    },
  },
  {
    path: '/school-data-hob/school/fund/:uuid',
    name: 'school-data-hob-fund',
    component: () => import('@/views/school-data/hob/SchoolDataFund.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SchoolDataHeadBranch',
    },
  },
]
export default schoolData
