const hob = [
  {
    path: '/hob',
    name: 'hob-list',
    component: () => import('@/views/hob/Hob.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'HobHeadDepartment',
    },
  },
  // {
  //   path: '/hob',
  //   name: 'hob-list',
  //   component: () => import('@/views/hob/Hob.vue'),
  //   meta: {
  //     layout: 'content',
  //     action: 'read',
  //     resource: 'HobHeadDepartment',
  //   },
  // },
]
export default hob
