const cbt = [
  {
    path: '/cbt-school',
    name: 'cbt-school',
    component: () => import('@/views/cbt/school/Cbt.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtSchool',
    },
  },
  {
    path: '/cbt-school/add',
    name: 'cbt-add-school',
    component: () => import('@/views/cbt/school/CbtAdd.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtSchool',
    },
  },
  {
    path: '/cbt-school/edit/:uuid',
    name: 'cbt-edit-school',
    component: () => import('@/views/cbt/school/CbtEdit.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtSchool',
    },
  },
  {
    path: '/cbt-school/cbt-and-result/:uuid',
    name: 'cbt-and-result-school',
    component: () => import('@/views/cbt/CbtAndResult.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtSchool',
    },
  },
  {
    path: '/cbt-school/cbt-and-result-detail-school/:cbtUuid/user/:userUuid',
    name: 'cbt-and-result-detail-school',
    component: () => import('@/views/cbt/result/CbtResultDetail.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtSchool',
    },
  },
  {
    path: '/cbt-headmaster',
    name: 'cbt-headmaster',
    component: () => import('@/views/cbt/headmaster/Cbt.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtHeadMaster',
    },
  },
  {
    path: '/cbt-headmaster/add',
    name: 'cbt-add-headmaster',
    component: () => import('@/views/cbt/headmaster/CbtAdd.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtHeadMaster',
    },
  },
  {
    path: '/cbt-headmaster/edit/:uuid',
    name: 'cbt-edit-headmaster',
    component: () => import('@/views/cbt/headmaster/CbtEdit.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtHeadMaster',
    },
  },
  {
    path: '/cbt-headmaster/cbt-and-result-headmaster/:uuid',
    name: 'cbt-and-result-headmaster',
    component: () => import('@/views/cbt/CbtAndResult.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtHeadMaster',
    },
  },
  {
    path: '/cbt-admin',
    name: 'cbt-admin',
    component: () => import('@/views/cbt/admin/Cbt.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtAdmin',
    },
  },
  {
    path: '/cbt-admin/add',
    name: 'cbt-add-admin',
    component: () => import('@/views/cbt/admin/CbtAdd.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtAdmin',
    },
  },
  {
    path: '/cbt-admin/edit/:uuid',
    name: 'cbt-edit-admin',
    component: () => import('@/views/cbt/admin/CbtEdit.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtAdmin',
    },
  },
  {
    path: '/cbt-admin/cbt-and-result-admin/:uuid',
    name: 'cbt-and-result-admin',
    component: () => import('@/views/cbt/CbtAndResult.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtAdmin',
    },
  },
  {
    path: '/cbt-admin/cbt-and-result-detail-admin/:cbtUuid/user/:userUuid',
    name: 'cbt-and-result-detail-admin',
    component: () => import('@/views/cbt/result/CbtResultDetail.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtAdmin',
    },
  },
  {
    path: '/cbt-hod',
    name: 'cbt-hod',
    component: () => import('@/views/cbt/hod/Cbt.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtHeadDepartment',
    },
  },
  {
    path: '/cbt-hod/add',
    name: 'cbt-add-hod',
    component: () => import('@/views/cbt/hod/CbtAdd.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtHeadDepartment',
    },
  },
  {
    path: '/cbt-hod/edit/:uuid',
    name: 'cbt-edit-hod',
    component: () => import('@/views/cbt/hod/CbtEdit.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtHeadDepartment',
    },
  },
  {
    path: '/cbt-hod/cbt-and-result-hod/:uuid',
    name: 'cbt-and-result-hod',
    component: () => import('@/views/cbt/CbtAndResult.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtHeadDepartment',
    },
  },
  {
    path: '/cbt-hod/cbt-and-result-detail-hod/:cbtUuid/user/:userUuid',
    name: 'cbt-and-result-detail-hod',
    component: () => import('@/views/cbt/result/CbtResultDetail.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'CbtHeadDepartment',
    },
  },
]
export default cbt
