const referencies = [
  {
    path: '/referency',
    name: 'referency-list',
    component: () => import('@/views/referencies/Referency.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'Referencies',
    },
  },
]
export default referencies
