const companies = [
  {
    path: '/company',
    name: 'company-list',
    component: () => import('@/views/companies/Company.vue'),
    meta: {
      layout: 'content',
    },
  },
]
export default companies
