const users = [
  {
    path: '/user',
    name: 'user-list',
    component: () => import('@/views/users/User.vue'),
    meta: {
      layout: 'content',
      resource: 'User',
      action: 'all',
    },
  },
  {
    path: '/user-add',
    name: 'user-add',
    component: () => import('@/views/users/UserAdd.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'user-list',
    },
  },
  {
    path: '/user/view/:uuid',
    name: 'user-view',
    component: () => import('@/views/users/user-view/UserView.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'user-list',
    },
  },
]

export default users
