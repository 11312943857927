import http from '@axios'
import paramsConverter from './utils/paramsConverter'

const resource = 'api/v1/publisher'

export default {
  list(params = {}) {
    const paramsString = paramsConverter(params)

    return http.get(`${resource}${paramsString}`)
  },
  add(publisher) {
    return http.post(`${resource}`, publisher)
  },
  edit(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  get(uuid) {
    return http.get(`${resource}/${uuid}`)
  },
  update(publisher, uuid) {
    return http.put(`${resource}/${uuid}`, publisher)
  },
  destroy(uuid) {
    return http.delete(`${resource}/${uuid}`)
  },
}
