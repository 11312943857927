const bankQuestion = [
  {
    path: '/bank-teacher',
    name: 'bank-teacher',
    component: () => import('@/views/bank/teacher/BankQuestion.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BankQuestionTeacher',
    },
  },
  {
    path: '/bank-teacher/detail/:uuid',
    name: 'bank-teacher-detail',
    component: () => import('@/views/bank/teacher/BankQuestionDetail.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BankQuestionTeacher',
    },
  },
  {
    path: '/bank-teacher/add',
    name: 'bank-teacher-add',
    component: () => import('@/views/bank/teacher/BankQuestionAdd.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BankQuestionTeacher',
    },
  },
  {
    path: '/bank-teacher/edit/:uuid',
    name: 'bank-teacher-edit',
    component: () => import('@/views/bank/teacher/BankQuestionEdit.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BankQuestionTeacher',
    },
  },

  {
    path: '/bank-headmaster',
    name: 'bank-headmaster',
    component: () => import('@/views/bank/headmaster/BankQuestion.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BankQuestionHeadMaster',
    },
  },
  {
    path: '/bank-headmaster/detail/:uuid',
    name: 'bank-headmaster-detail',
    component: () => import('@/views/bank/headmaster/BankQuestionDetail.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BankQuestionHeadMaster',
    },
  },
  {
    path: '/bank-headmaster/add',
    name: 'bank-headmaster-add',
    component: () => import('@/views/bank/headmaster/BankQuestionAdd.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BankQuestionHeadMaster',
    },
  },
  {
    path: '/bank-headmaster/edit/:uuid',
    name: 'bank-headmaster-edit',
    component: () => import('@/views/bank/headmaster/BankQuestionEdit.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BankQuestionHeadMaster',
    },
  },
  // HeadDepartment
  {
    path: '/bank-hod',
    name: 'bank-hod',
    component: () => import('@/views/bank/hod/BankQuestion.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BankQuestionHeadDepartment',
    },
  },
  {
    path: '/bank-hod/detail/:uuid',
    name: 'bank-hod-detail',
    component: () => import('@/views/bank/hod/BankQuestionDetail.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BankQuestionHeadDepartment',
    },
  },
  {
    path: '/bank-hod/add',
    name: 'bank-hod-add',
    component: () => import('@/views/bank/hod/BankQuestionAdd.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BankQuestionHeadDepartment',
    },
  },
  {
    path: '/bank-hod/edit/:uuid',
    name: 'bank-hod-edit',
    component: () => import('@/views/bank/hod/BankQuestionEdit.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BankQuestionHeadDepartment',
    },
  }, // Admin
  {
    path: '/bank-admin',
    name: 'bank-admin',
    component: () => import('@/views/bank/admin/BankQuestion.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BankQuestionAdmin',
    },
  },
  {
    path: '/bank-admin/detail/:uuid',
    name: 'bank-admin-detail',
    component: () => import('@/views/bank/admin/BankQuestionDetail.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BankQuestionAdmin',
    },
  },
  {
    path: '/bank-admin/add',
    name: 'bank-admin-add',
    component: () => import('@/views/bank/admin/BankQuestionAdd.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BankQuestionAdmin',
    },
  },
  {
    path: '/bank-admin/edit/:uuid',
    name: 'bank-admin-edit',
    component: () => import('@/views/bank/admin/BankQuestionEdit.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'BankQuestionAdmin',
    },
  },
]
export default bankQuestion
