const sliders = [
  {
    path: '/slider-superadmin',
    name: 'slider-superadmin-list',
    component: () => import('@/views/sliders/superadmin/Slider.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SliderAdmin',
    },
  },
  {
    path: '/slider-admin',
    name: 'slider-admin-list',
    component: () => import('@/views/sliders/admin/Slider.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SliderSchool',
    },
  },
  {
    path: '/slider-hod',
    name: 'slider-hod-list',
    component: () => import('@/views/sliders/hod/Slider.vue'),
    meta: {
      layout: 'content',
      action: 'read',
      resource: 'SliderHeadDepartment',
    },
  },
]
export default sliders
