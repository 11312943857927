import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'
import announcements from './announcement'
import apps from './app'
import archive from './archive'
import attendances from './attendance'
import bankCategory from './bankcategory'
import bankIcon from './bankicon'
import bankQuestion from './bankquestion'
import book from './book'
import calendar from './calendar'
import cbt from './cbt'
import certificate from './certificate'
import companies from './company'
import dashboard from './dashboard'
import elearning from './elearning'
import eraport from './eraport'
import evoting from './evoting'
import faq from './faq'
import forum from './forum'
import functionaries from './functionary'
import greeting from './greeting'
import hob from './hob'
import hod from './hod'
import leaderboards from './leaderboard'
import lessons from './lesson'
import library from './library'
import locations from './location'
import majors from './majors'
import news from './news'
import packets from './packet'
import promotion from './promotion'
import referencies from './referency'
import school from './school'
import schoolData from './schooldata'
import schoolFund from './schoolfund'
import scores from './score'
import sliders from './slider'
import students from './student'
import users from './user'
import violations from './violation'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      const user = JSON.parse(localStorage.getItem('user'))
      const userRole = user && user.role ? user.role : null

      if (user?.user?.role === 'admin') return { name: 'dashboard-admin' }
      if (user?.user?.role === 'school') return { name: 'dashboard-school' }
      if (user?.user?.role === 'teacher') return { name: 'dashboard-teacher' }
      if (user?.user?.role === 'headmaster') return { name: 'dashboard-headmaster' }
      if (user?.user?.role === 'headdepartment') return { name: 'dashboard-hod' }
      if (user?.user?.role === 'headbranch') return { name: 'school-data-hob-list' }

      return { name: 'dashboard-school', query: to.query }
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/misc-not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  // {
  //   path: '/register',
  //   name: 'auth-register',
  //   component: () => import('@/views/Register.vue'),
  //   meta: {
  //     layout: 'blank',
  //     resource: 'Public',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password/:token',
    name: 'auth-reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/success-verification',
    name: 'success-verification',
    component: () => import('@/views/SuccessVerification.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/pivot',
    name: 'pivot',
    component: () => import('@/views/components/DataTablePaginationPivot.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  ...apps,
  ...attendances,
  ...announcements,
  ...bankIcon,
  ...bankQuestion,
  ...book,
  ...archive,
  ...bankCategory,
  ...cbt,
  ...companies,
  ...dashboard,
  ...elearning,
  ...evoting,
  ...eraport,
  ...faq,
  ...functionaries,
  ...forum,
  ...hob,
  // ...histories,
  ...hod,
  ...greeting,
  ...locations,
  ...lessons,
  ...library,
  ...leaderboards,
  ...majors,
  ...news,
  ...packets,
  ...promotion,
  ...referencies,
  ...school,
  ...scores,
  ...students,
  ...users,
  ...violations,
  ...calendar,
  ...sliders,
  ...certificate,
  ...schoolFund,
  ...schoolData,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, _, next) => {
  const publicPages = [
    'auth-login',
    // 'auth-register',
    'auth-reset-password',
    'auth-forgot-password',
    'success-verification',
    'pivot',
  ]
  const authRequired = !publicPages.includes(to.name)
  const user = localStorage.getItem('user')
  const ability = JSON.parse(localStorage.getItem('userAbility'))
  const isLoggedIn = user && ability

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (authRequired && !isLoggedIn) return next('/login')

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
